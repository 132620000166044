import { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";
import TablePagination from "../components/TablePagination";
import OuterBodyModal from "../components/modals/OuterBodyModal";
import { Button } from "react-bootstrap";
import AddEditPayments from "../components/modals/Add/AddEditPayments";
import DeleteConfirmationModal from "../components/modals/DeleteConfirmationModal";

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add Payment");
  const [showDelModal, setShowDelModal] = useState(false);

  const initialFormData = {
    id: "",
    invoiceNo: "",
    invoiceDate: new Date(),
    mop: null,
    ledger: "",
    outstandingBalance: "",
    narration: "",
    amount: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const getPaymentsData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/getPaymentsData`, {
        params: {
          exclude: "createdAt,updatedAt",
        },
      });
      setPayments(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaymentsData();
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setShowDelModal(false);
  }, []);

  const onClickAdd = useCallback(() => {
    setModalTitle("Add Payment");
    setFormData(initialFormData);
    setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickEdit = useCallback(
    (id) => {
      const getPaymentById = async (id) => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/getPaymentById?id=${id}`
          );
          setFormData({
            ...formData,
            id: response.data.id,
            invoiceNo: response.data.invoice_no,
            invoiceDate: new Date(response.data.invoice_date),
            mop: { value: response.data.mop, label: response.data.mop },
            ledger: response.data.ledger,
            outstandingBalance: response.data.outstanding_balance,
            narration: response.data.narration,
            amount: response.data.amount,
          });
        } catch (error) {
          console.log(error);
        }
      };

      console.log("Edit ID: " + id);
      getPaymentById(id);
      setModalTitle("Edit Payment");
      setShowModal(true);
    },
    [formData]
  );

  const onClickDel = useCallback(
    (id) => {
      setFormData({
        ...formData,
        id: id,
      });
      setShowDelModal(true);
    },
    [formData]
  );

  const onSubmitPayments = useCallback(() => {
    setShowModal(false);
    getPaymentsData();
  }, []);

  const handleSubmitAlert = useCallback(
    async (event) => {
      event.preventDefault();
      const clickedButton = event.nativeEvent.submitter;
      const buttonValue = clickedButton.getAttribute("name");

      if (buttonValue === "Yes") {
        console.log("Yes button clicked and id is: " + formData.id);
        try {
          const res = await axios.post(`${API_BASE_URL}/api/delPayment`, {
            id: formData.id,
          });
          if (res.data.message) {
            console.log(res.data.message);
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        } catch (err) {
          console.log(err);
        }
        setShowDelModal(false);
        getPaymentsData();
      } else if (buttonValue === "No") {
        handleCloseModal();
      }
    },
    [formData, handleCloseModal]
  );

  const memoizedPayments = useMemo(() => payments, [payments]);

  return (
    <>
      {payments ? (
        <Fragment>
          <Button className="m-3" onClick={onClickAdd}>
            + Add
          </Button>
          <TablePagination
            key={JSON.stringify(memoizedPayments)}
            tabledata={memoizedPayments}
            initialRowsPerPage="5"
            showEditOption={true}
            showDeleteOption={true}
            onClickEdit={onClickEdit}
            onClickDel={onClickDel}
          ></TablePagination>
          <OuterBodyModal
            modalTitle={modalTitle}
            showModal={showModal}
            handleClose={handleCloseModal}
          >
            <AddEditPayments
              initialFormData={formData}
              type={modalTitle}
              onHandleSubmit={onSubmitPayments}
            ></AddEditPayments>
          </OuterBodyModal>
          <DeleteConfirmationModal
            showModal={showDelModal}
            handleClose={handleCloseModal}
            handleSubmitAlert={handleSubmitAlert}
          />
        </Fragment>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default Payments;
