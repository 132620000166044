import { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";
import TablePagination from "../components/TablePagination";
import OuterBodyModal from "../components/modals/OuterBodyModal";
import { Button } from "react-bootstrap";
import AddEditProduct from "../components/modals/Add/AddEditProduct";
import DeleteConfirmationModal from "../components/modals/DeleteConfirmationModal";
import AlertModal from "../components/modals/AlertModal";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add Product");
  const [showDelModal, setShowDelModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const initialFormData = {
    id: "",
    productName: "",
    units: [],
    validationError: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const getProductsData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/getProductsGroupBy`
      );
      setProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductsData();
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setShowDelModal(false);
    setShowAlertModal(false);
  }, []);

  const onClickAdd = useCallback(() => {
    setModalTitle("Add Product");
    setFormData(initialFormData);
    setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickEdit = useCallback(
    (id) => {
      const getProductById = async (id) => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/getProductById?id=${id}`
          );
          const { product_name, unit } = response.data;
          setFormData({
            ...formData,
            id: id,
            productName: product_name,
            units: unit.split(","),
          });
        } catch (error) {
          console.log(error);
        }
      };

      console.log("Edit ID: " + id);
      getProductById(id);
      setModalTitle("Edit Product");
      setShowModal(true);
    },
    [formData]
  );

  const onClickDel = useCallback(
    (id) => {
      setFormData({
        ...formData,
        id: id,
      });
      setShowDelModal(true);
    },
    [formData]
  );

  const onSubmitProducts = useCallback(() => {
    setShowModal(false);
    getProductsData();
  }, []);

  const handleSubmitAlert = useCallback(
    async (event) => {
      event.preventDefault();
      const clickedButton = event.nativeEvent.submitter;
      const buttonValue = clickedButton.getAttribute("name");

      if (buttonValue === "Yes") {
        console.log("Yes button clicked and id is: " + formData.id);
        try {
          const res = await axios.post(`${API_BASE_URL}/api/delProducts`, {
            id: formData.id,
          });
          if (res.data.message) {
            console.log(res.data.message);
            setAlertMessage("Product deleted successfully");
          } else if (res.data.error) {
            console.log(res.data.error);
            setAlertMessage("Error: " + res.data.error);
          }
        } catch (err) {
          console.log(err);
        }
        setShowDelModal(false);
        setShowAlertModal(true);
        getProductsData();
      } else if (buttonValue === "No") {
        handleCloseModal();
      }
    },
    [formData, handleCloseModal]
  );

  const memoizedProducts = useMemo(() => products, [products]);
  return (
    <>
      {products ? (
        <Fragment>
          <Button className="m-3" onClick={onClickAdd}>
            + Add
          </Button>
          <TablePagination
            key={JSON.stringify(memoizedProducts)}
            tabledata={memoizedProducts}
            initialRowsPerPage="5"
            showEditOption={true}
            showDeleteOption={true}
            onClickEdit={onClickEdit}
            onClickDel={onClickDel}
          ></TablePagination>
          <OuterBodyModal
            modalTitle={modalTitle}
            showModal={showModal}
            handleClose={handleCloseModal}
          >
            <AddEditProduct
              initialFormData={formData}
              type={modalTitle}
              onHandleSubmit={onSubmitProducts}
            ></AddEditProduct>
          </OuterBodyModal>
          <DeleteConfirmationModal
            showModal={showDelModal}
            handleClose={handleCloseModal}
            handleSubmitAlert={handleSubmitAlert}
          />
          <AlertModal
            showModal={showAlertModal}
            handleClose={handleCloseModal}
            modalTitle="Alert"
            message={alertMessage}
          />
        </Fragment>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default Products;
