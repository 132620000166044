import { Link, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import WalletRoundedIcon from "@mui/icons-material/WalletRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ShieldRoundedIcon from "@mui/icons-material/ShieldRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

const SidebarPro = ({ isCollapsed, setIsCollapsed, data, onLogout }) => {
  const navigate = useNavigate();

  const logout = () => {
    onLogout();
    navigate("/");
  };
  return (
    <Sidebar
      className="app"
      collapsed={isCollapsed}
      backgroundColor="rgb(34, 193, 195)"
    >
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
            if (level === 0)
              return {
                color: disabled ? "#f5d9ff" : "black",
                backgroundColor: active ? "#eecef9" : undefined,
              };
          },
        }}
      >
        <MenuItem
          className="menu1"
          icon={
            <MenuRoundedIcon
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
            />
          }
        >
          <h2>PJV</h2>
        </MenuItem>
        <MenuItem
          component={<Link to="/dashboard" className="link" />}
          icon={<GridViewRoundedIcon />}
        >
          Dashboard
        </MenuItem>
        <SubMenu label="Master" icon={<BarChartRoundedIcon />}>
          <MenuItem
            icon={<TimelineRoundedIcon />}
            component={<Link to="/products" className="link" />}
          >
            {" "}
            Products{" "}
          </MenuItem>
          <MenuItem
            icon={<BubbleChartRoundedIcon />}
            component={<Link to="/customer" className="link" />}
          >
            Customer
          </MenuItem>
          <MenuItem
            icon={<ReceiptRoundedIcon />}
            component={<Link to="/vendor" className="link" />}
          >
            Vendor
          </MenuItem>
          <MenuItem
            icon={<BubbleChartRoundedIcon />}
            component={<Link to="/expenses" className="link" />}
          >
            Expenses
          </MenuItem>
          <MenuItem
            icon={<BubbleChartRoundedIcon />}
            component={<Link to="/accountgroup" className="link" />}
          >
            Account Groups
          </MenuItem>
          <MenuItem
            icon={<BubbleChartRoundedIcon />}
            component={<Link to="/ledgergroup" className="link" />}
          >
            Ledger Groups
          </MenuItem>
        </SubMenu>
        <SubMenu label="Billing" icon={<WalletRoundedIcon />}>
          <MenuItem
            icon={<AccountBalanceRoundedIcon />}
            component={<Link to="/sales" className="link" />}
          >
            Sales
          </MenuItem>
          <MenuItem
            icon={<SavingsRoundedIcon />}
            component={<Link to="/purchase" className="link" />}
          >
            Purchase
          </MenuItem>
        </SubMenu>
        <SubMenu label="Accounts" icon={<SettingsApplicationsRoundedIcon />}>
          <MenuItem
            icon={<AccountCircleRoundedIcon />}
            component={<Link to="/payments" className="link" />}
          >
            {" "}
            Payments{" "}
          </MenuItem>
          <MenuItem
            icon={<ShieldRoundedIcon />}
            component={<Link to="/receipts" className="link" />}
          >
            {" "}
            Receipts{" "}
          </MenuItem>
          <MenuItem
            icon={<NotificationsRoundedIcon />}
            component={<Link to="/trialBalance" className="link" />}
          >
            Trial Balance
          </MenuItem>
          <MenuItem
            icon={<NotificationsRoundedIcon />}
            component={<Link to="/profitAndLoss" className="link" />}
          >
            Profit And Loss
          </MenuItem>
          <MenuItem
            icon={<NotificationsRoundedIcon />}
            component={<Link to="/balanceSheet" className="link" />}
          >
            Balance Sheet
          </MenuItem>
        </SubMenu>
        <SubMenu label="Reports" icon={<WalletRoundedIcon />}>
          <MenuItem
            icon={<MonetizationOnRoundedIcon />}
            component={<Link to="/purchaseReport" className="link" />}
          >
            Purchase Report
          </MenuItem>
          <MenuItem
            icon={<SavingsRoundedIcon />}
            component={<Link to="/salesReport" className="link" />}
          >
            Sales Report
          </MenuItem>
        </SubMenu>
        <MenuItem
          icon={<LogoutRoundedIcon />}
          // component={<Link to="/" className="link" />}
          onClick={logout}
        >
          {" "}
          Logout{" "}
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default SidebarPro;
