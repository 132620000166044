import { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";
import TablePagination from "../components/TablePagination";
import OuterBodyModal from "../components/modals/OuterBodyModal";
import { Button } from "react-bootstrap";
import AddEditAccountGroup from "../components/modals/Add/AddEditAccountGroup";
import DeleteConfirmationModal from "../components/modals/DeleteConfirmationModal";

const AccountGroup = () => {
  const [accountGroupData, setAccountGroupData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add Account Group");
  const [showDelModal, setShowDelModal] = useState(false);

  const initialFormData = {
    id: "",
    baseGroup: "",
    accountGroup: "",
    validationError: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const getAccountGroupData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/getAccountGroupData`,
        {
          params: {
            exclude: "createdAt,updatedAt",
          },
        }
      );
      setAccountGroupData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccountGroupData();
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setShowDelModal(false);
  }, []);

  const onClickAdd = useCallback(() => {
    setModalTitle("Add Account Group");
    setFormData(initialFormData);
    setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickEdit = useCallback(
    (id) => {
      const getAccountGroupById = async (id) => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/getAccountGroupById?id=${id}`
          );
          setFormData({
            ...formData,
            id: id,
            baseGroup: response.data.base_group,
            accountGroup: response.data.account_group,
          });
        } catch (error) {
          console.log(error);
        }
      };

      console.log("Edit ID: " + id);
      getAccountGroupById(id);
      setModalTitle("Edit Account Group");
      setShowModal(true);
    },
    [formData]
  );

  const onClickDel = useCallback(
    (id) => {
      setFormData({
        ...formData,
        id: id,
      });
      setShowDelModal(true);
    },
    [formData]
  );

  const onSubmitExpenses = useCallback(() => {
    setShowModal(false);
    getAccountGroupData();
  }, []);

  const handleSubmitAlert = useCallback(
    async (event) => {
      event.preventDefault();
      const clickedButton = event.nativeEvent.submitter;
      const buttonValue = clickedButton.getAttribute("name");

      if (buttonValue === "Yes") {
        console.log("Yes button clicked and id is: " + formData.id);
        try {
          const res = await axios.post(`${API_BASE_URL}/api/delAccountGroup`, {
            id: formData.id,
          });
          if (res.data.message) {
            console.log(res.data.message);
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        } catch (err) {
          console.log(err);
        }
        setShowDelModal(false);
        getAccountGroupData();
      } else if (buttonValue === "No") {
        handleCloseModal();
      }
    },
    [formData, handleCloseModal]
  );

  const memoizedAccountGroupData = useMemo(
    () => accountGroupData,
    [accountGroupData]
  );

  return (
    <>
      {accountGroupData ? (
        <Fragment>
          <Button className="m-3" onClick={onClickAdd}>
            + Add
          </Button>
          <TablePagination
            key={JSON.stringify(memoizedAccountGroupData)}
            tabledata={memoizedAccountGroupData}
            initialRowsPerPage="5"
            showEditOption={true}
            showDeleteOption={true}
            onClickEdit={onClickEdit}
            onClickDel={onClickDel}
          ></TablePagination>
          <OuterBodyModal
            modalTitle={modalTitle}
            showModal={showModal}
            handleClose={handleCloseModal}
          >
            <AddEditAccountGroup
              initialFormData={formData}
              type={modalTitle}
              onHandleSubmit={onSubmitExpenses}
            ></AddEditAccountGroup>
          </OuterBodyModal>
          <DeleteConfirmationModal
            showModal={showDelModal}
            handleClose={handleCloseModal}
            handleSubmitAlert={handleSubmitAlert}
          />
        </Fragment>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default AccountGroup;
