import React from "react";

const ConvertStringToSelectOptions = ({ valueString, children }) => {
  const convertOptions = (valueString) => {
    if (!valueString) return [];

    const values = valueString.split(",");
    return values.map((value) => ({ value, label: value.trim() }));
  };

  const options = convertOptions(valueString);

  return <>{children(options)}</>;
};

export default ConvertStringToSelectOptions;
