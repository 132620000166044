import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";
import SearchLedgerModal from "../SearchLedgerModal";

const AddEditPayments = (props) => {
  const initialFormData = {
    id: "",
    invoiceNo: "",
    invoiceDate: new Date(),
    mop: null,
    ledger: "",
    outstandingBalance: "",
    narration: "",
    amount: "",
  };

  const [formData, setFormData] = useState(props.initialFormData);
  const [mopOptions, setMopOptions] = useState([]);
  const [showSearchModal, setShowSearchModal] = useState(false);

  const getMopOptions = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/getMopOptions`);
      const options = response.data.map((mop) => ({
        value: mop.ledger_group,
        label: mop.ledger_group,
      }));
      setMopOptions(options);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMopOptions();
  }, []);

  useEffect(() => {
    setFormData(props.initialFormData);
  }, [props.initialFormData]);

  const getInvoiceNo = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/getInvoiceSeriesByType?series_name=payment`
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        invoiceNo: response.data.next_number,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInvoiceNo();
  }, []);

  const onClickClear = () => {
    setFormData(initialFormData);
    getInvoiceNo();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !formData.invoiceDate ||
      !formData.mop ||
      !formData.ledger ||
      formData.outstandingBalance === "" ||
      !formData.amount
    ) {
      return setFormData({
        ...formData,
        validationError: "Please fill all details",
      });
    }
    try {
      if (props.type === "Add Payment") {
        const res = await axios.post(`${API_BASE_URL}/api/addPayment`, {
          payment_data: formData,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          setFormData(initialFormData);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      } else if (props.type === "Edit Payment") {
        const res = await axios.post(`${API_BASE_URL}/api/updatePayment`, {
          id: formData.id,
          invoiceNo: formData.invoiceNo,
          invoiceDate: formData.invoiceDate,
          mop: formData.mop,
          ledger: formData.ledger,
          outstandingBalance: formData.outstandingBalance,
          narration: formData.narration,
          amount: formData.amount,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClickSearch = () => {
    setShowSearchModal(true);
  };

  const handleCloseModal = () => {
    setShowSearchModal(false);
  };

  const handleUpdateNameBalance = async (name, balance) => {
    setFormData({
      ...formData,
      ledger: name,
      outstandingBalance: Number(balance),
    });
    handleCloseModal();
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="float-end">
              <Link onClick={onClickClear}>Clear</Link>
            </div>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Invoice No</Form.Label>
              <Form.Control
                type="text"
                value={formData.invoiceNo}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    invoiceNo: event.target.value,
                  })
                }
                readOnly={true}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Date</Form.Label>
              <DatePicker
                selected={formData.invoiceDate}
                onChange={(date) =>
                  setFormData({
                    ...formData,
                    invoiceDate: date,
                  })
                }
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Mode Of Payment</Form.Label>
              <Select
                value={formData.mop}
                onChange={(option) => {
                  setFormData({
                    ...formData,
                    mop: option,
                  });
                }}
                options={mopOptions}
                placeholder="-- Select MOP --"
                isSearchable={true}
                isClearable={true}
              />
            </Form.Group>
          </Col>
          <Col xs={9}>
            <Form.Group>
              <Form.Label>Ledger</Form.Label>
              <Form.Control
                type="text"
                value={formData.ledger}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    ledger: event.target.value,
                  })
                }
                required
                readOnly={true}
              />
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group>
              <Form.Label>Search</Form.Label>
            </Form.Group>
            <Button onClick={onClickSearch}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </Button>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Outstanding Balance</Form.Label>
              <Form.Control
                type="number"
                value={formData.outstandingBalance}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    outstandingBalance: event.target.value,
                  })
                }
                required
                readOnly={true}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Amount"
                value={formData.amount}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    amount: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Narration</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Narration"
                value={formData.narration}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    narration: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Button className="mt-3" type="submit">
              Submit
            </Button>
          </Col>
          <Col xs={12}>
            <p className="text-danger">{formData.validationError}</p>
          </Col>
        </Row>
      </Form>
      <SearchLedgerModal
        modalTitle="Search"
        showModal={showSearchModal}
        handleClose={handleCloseModal}
        handleUpdateNameBalance={handleUpdateNameBalance}
      />
    </Fragment>
  );
};

export default AddEditPayments;
