import React, { Fragment, useState, useEffect } from "react";
import OuterBodyModal from "./OuterBodyModal";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import ConvertStringToSelectOptions from "../ConvertStringToSelectOptions";

const SelectUnitModal = ({
  modalTitle,
  showModal,
  handleClose,
  handleUpdateUnit,
  units,
}) => {
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [kgsPerUnit, setKgsPerUnit] = useState("");
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    setSelectedUnit(null);
    setKgsPerUnit("");
    setValidationError("");
  }, [showModal]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (selectedUnit) {
      handleUpdateUnit(selectedUnit.value, kgsPerUnit);
    } else {
      setValidationError("Please Select Unit");
    }
  };

  return (
    <Fragment>
      <OuterBodyModal
        modalTitle={modalTitle}
        showModal={showModal}
        handleClose={handleClose}
      >
        <Form onSubmit={onSubmitHandler}>
          <ConvertStringToSelectOptions valueString={units}>
            {(options) => (
              <Select
                value={selectedUnit}
                onChange={(selectedOption) => {
                  setSelectedUnit(selectedOption);
                }}
                options={options}
                placeholder="-- Select Unit --"
                isSearchable={true}
                isClearable={true}
              />
            )}
          </ConvertStringToSelectOptions>
          <Form.Control
            type="number"
            value={kgsPerUnit}
            onChange={(event) => setKgsPerUnit(event.target.value)}
            placeholder="Enter Kgs"
            className="mt-2"
            required
          />
          <p className="text-danger">{validationError}</p>
          <Button type="submit" className="mt-2">
            Submit
          </Button>
        </Form>
      </OuterBodyModal>
    </Fragment>
  );
};

export default SelectUnitModal;
