import { Fragment, useState } from "react";
import PurchaseDayBook from "../components/PurchaseDayBook";
import PurchaseDayBookDetail from "../components/PurchaseDayBookDetail";
import DatePicker from "react-datepicker";
import { Row, Col, Button } from "react-bootstrap";
import Select from "react-select";

const PurchaseReport = () => {
  const initialFormData = {
    fromDate: new Date(),
    toDate: new Date(),
    reportType: null,
    validationError: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [buttonLabel, setButtonLabel] = useState("Create Report");

  const reportTypeOptions = [
    { value: "Day Book", label: "Day Book" },
    { value: "Day Book Detail", label: "Day Book Detail" },
  ];

  const onClickBtn = () => {
    if (!formData.reportType || !formData.fromDate || !formData.toDate) {
      setFormData({
        ...formData,
        validationError: "Please Select All",
      });
    } else {
      setFormData({
        ...formData,
        validationError: "",
      });
      buttonLabel === "Create Report"
        ? setButtonLabel("Back")
        : setButtonLabel("Create Report");
    }
  };

  return (
    <Fragment>
      <div className="m-3">
        {buttonLabel === "Create Report" && (
          <Row>
            <Col className="col-lg-4 col-md-4 col-12">
              <DatePicker
                selected={formData.fromDate}
                onChange={(date) =>
                  setFormData({
                    ...formData,
                    fromDate: date,
                  })
                }
                className="form-control"
              />
            </Col>
            <Col className="col-lg-4 col-md-4 col-12">
              <DatePicker
                selected={formData.toDate}
                onChange={(date) =>
                  setFormData({
                    ...formData,
                    toDate: date,
                  })
                }
                className="form-control"
              />
            </Col>
            <Col className="col-lg-4 col-md-4 col-12">
              <Select
                value={formData.reportType}
                onChange={(option) => {
                  setFormData({
                    ...formData,
                    reportType: option,
                  });
                }}
                options={reportTypeOptions}
                placeholder="-- Select Report Type --"
                isSearchable={true}
                isClearable={true}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col className="col-12 text-center mt-3">
            <Button onClick={onClickBtn}>{buttonLabel}</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center mt-2">
            <h6 className="text-danger">{formData.validationError}</h6>
          </Col>
        </Row>
        {buttonLabel === "Back" &&
          formData.reportType &&
          formData.reportType.value === "Day Book" && (
            <PurchaseDayBook formData={formData} />
          )}
        {buttonLabel === "Back" &&
          formData.reportType &&
          formData.reportType.value === "Day Book Detail" && (
            <PurchaseDayBookDetail formData={formData} />
          )}
      </div>
    </Fragment>
  );
};

export default PurchaseReport;
