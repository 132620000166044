import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./components/AuthContext";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./pages/Dashboard";
import Products from "./pages/Products";
import Customer from "./pages/Customer";
import Vendor from "./pages/Vendor";
import Purchase from "./pages/Purchase";
import Sales from "./pages/Sales";
import Expenses from "./pages/Expenses";
import AccountGroup from "./pages/AccountGroup";
import LedgerGroup from "./pages/LedgerGroup";
import Payments from "./pages/Payments";
import Receipts from "./pages/Receipts";
import TrialBalance from "./pages/TrialBalance";
import ProfitAndLoss from "./pages/ProfitAndLoss";
import BalanceSheet from "./pages/BalanceSheet";
import PurchaseReport from "./pages/PurchaseReport";
import SalesReport from "./pages/SalesReport";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} exact />
            <Route path="/products" element={<Products />} exact />
            <Route path="/customer" element={<Customer />} exact />
            <Route path="/vendor" element={<Vendor />} exact />
            <Route path="/purchase" element={<Purchase />} exact />
            <Route path="/sales" element={<Sales />} exact />
            <Route path="/expenses" element={<Expenses />} exact />
            <Route path="/accountGroup" element={<AccountGroup />} exact />
            <Route path="/ledgerGroup" element={<LedgerGroup />} exact />
            <Route path="/payments" element={<Payments />} exact />
            <Route path="/receipts" element={<Receipts />} exact />
            <Route path="/trialBalance" element={<TrialBalance />} exact />
            <Route path="/profitAndLoss" element={<ProfitAndLoss />} exact />
            <Route path="/balanceSheet" element={<BalanceSheet />} exact />
            <Route path="/purchaseReport" element={<PurchaseReport />} exact />
            <Route path="/salesReport" element={<SalesReport />} exact />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
