import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Select, { components } from "react-select";
import { API_BASE_URL } from "../config";
import axios from "axios";
import styles from "./InvoiceFooterTable.module.css";
import AddEditExpenses from "./modals/Add/AddEditExpenses";
import OuterBodyModal from "./modals/OuterBodyModal";

const InvoiceFooterTable = ({
  formData,
  updateFormData,
  initialExpense,
  totalAmt,
}) => {
  const [selectedDiscountType, setSelectedDiscountType] = useState(null);
  const [discountInput, setDiscountInput] = useState("");
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [expenseOptions, setExpenseOptions] = useState([]);
  const initialExpenseFormData = {
    id: "",
    description: "",
    validationError: "",
  };
  const discountOptions = [
    { value: "Discount %", label: "Less %" },
    { value: "Discount Price", label: "Less Price" },
  ];

  const getExpenseOptions = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/getExpenseOptions`,
        {
          params: {
            exclude: "createdAt,updatedAt",
          },
        }
      );
      const expenseOptionsData = [
        { value: "addButton", label: "+", isButton: true },
        ...response.data,
      ];

      setExpenseOptions(expenseOptionsData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getExpenseOptions();
  }, []);

  const onClickAdd = useCallback(() => {
    setShowExpenseModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowExpenseModal(false);
  }, []);

  const onSubmitExpenses = useCallback(() => {
    setShowExpenseModal(false);
    getExpenseOptions();
  }, []);

  useEffect(() => {
    if (selectedDiscountType) {
      if (selectedDiscountType.value === "Discount %") {
        const discount =
          (parseFloat(discountInput === "" ? 0 : discountInput) *
            parseFloat(totalAmt)) /
          100;
        updateFormData((prevData) => ({
          ...prevData,
          discount: discount.toFixed(2),
        }));
      } else if (selectedDiscountType.value === "Discount Price") {
        updateFormData((prevData) => ({
          ...prevData,
          discount: parseFloat(
            discountInput === "" ? 0 : discountInput
          ).toFixed(2),
        }));
      }
    } else {
      updateFormData((prevData) => ({
        ...prevData,
        discount: 0.0,
      }));
    }
  }, [selectedDiscountType, discountInput, totalAmt, updateFormData]);

  const handleExpenseSelectChange = (selectedOption, index) => {
    if (selectedOption && selectedOption.isButton) {
      onClickAdd();
    } else {
      const expenseListCopy = [...formData.expenseList];
      expenseListCopy[index] = {
        ...expenseListCopy[index],
        expenseId: selectedOption,
      };
      if (!selectedOption) {
        expenseListCopy[index].amount = "";
      }
      updateFormData({
        ...formData,
        expenseList: expenseListCopy,
      });
    }
  };

  const handleAddRowExpense = useCallback(() => {
    updateFormData((prevData) => {
      const expenseListCopy = [...prevData.expenseList];
      expenseListCopy.push(initialExpense);
      return {
        ...prevData,
        expenseList: expenseListCopy,
      };
    });
  }, [initialExpense, updateFormData]);

  const onClickDel = (index) => {
    updateFormData((prevData) => {
      const expenseListCopy = [...prevData.expenseList];
      expenseListCopy.splice(index, 1);
      return {
        ...prevData,
        expenseList: expenseListCopy,
      };
    });
  };

  const handleExpenseAmountChange = (event, index) => {
    const updatedExpense = {
      ...formData.expenseList[index],
      amount: event.target.value,
    };
    const updatedExpenseList = [...formData.expenseList];
    updatedExpenseList[index] = updatedExpense;
    const totalAmt = updatedExpenseList.reduce(
      (total, expense) => total + Number(expense.amount),
      0
    );

    updateFormData((prevData) => ({
      ...prevData,
      expenseList: updatedExpenseList,
      otherExpenses: Number(totalAmt.toFixed(2)),
    }));
  };

  return (
    <Fragment>
      <div className={`${styles.tableResponsive} table-responsive`}>
        <table className="table table-striped text-center table-bordered table-info mt-2">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Other Expense</th>
              <th>Expense Amount</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {formData.expenseList.map((expense, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <Form.Group>
                    <Select
                      value={expense.expenseId}
                      onChange={(selectedOption) =>
                        handleExpenseSelectChange(selectedOption, index)
                      }
                      options={expenseOptions}
                      placeholder="-- Select Expense Type --"
                      isSearchable={true}
                      isClearable={true}
                      className={styles.input}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      components={{
                        Option: (props) => {
                          if (props.data.isButton) {
                            return (
                              <Button
                                className="m-3"
                                onClick={() => {
                                  onClickAdd();
                                }}
                              >
                                + Add
                              </Button>
                            );
                          } else {
                            return <components.Option {...props} />;
                          }
                        },
                      }}
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group>
                    <Form.Control
                      type="number"
                      placeholder="Enter Expense Amount"
                      value={expense.amount}
                      onChange={(event) =>
                        handleExpenseAmountChange(event, index)
                      }
                      required
                      readOnly={!expense.expenseId}
                      className={styles.input}
                    />
                  </Form.Group>
                </td>
                <td>
                  <i
                    className={`fa-sharp fa-solid fa-trash text-danger ${styles.clickable}`}
                    onClick={() => {
                      onClickDel(index);
                    }}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Button onClick={handleAddRowExpense}>Add Expense</Button>
      <Row>
        <Col lg={6} className="mt-3">
          <Form.Group>
            <Form.Label>Less</Form.Label>
            <Select
              value={selectedDiscountType}
              onChange={(selectedOption) => {
                setSelectedDiscountType(selectedOption);
                if (!selectedOption) {
                  setDiscountInput("");
                }
              }}
              options={discountOptions}
              placeholder="-- Select Type --"
              isSearchable={true}
              isClearable={true}
            />
          </Form.Group>
        </Col>
        <Col lg={6} className="mt-3">
          <Form.Group>
            <Form.Label>Less Input</Form.Label>
            <Form.Control
              type="number"
              placeholder={"Enter Less Input"}
              value={discountInput}
              onChange={(event) => setDiscountInput(event.target.value)}
              step="0.01"
              required
              readOnly={!selectedDiscountType}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Col className="mt-3">
            <Form.Group>
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Notes"
                value={formData.notes}
                onChange={(event) => {
                  updateFormData({
                    ...formData,
                    notes: event.target.value,
                  });
                }}
              />
            </Form.Group>
          </Col>
        </Col>
      </Row>
      <OuterBodyModal
        modalTitle="Add Expense"
        showModal={showExpenseModal}
        handleClose={handleCloseModal}
      >
        <AddEditExpenses
          initialFormData={initialExpenseFormData}
          type="Add Expense"
          onHandleSubmit={onSubmitExpenses}
        ></AddEditExpenses>
      </OuterBodyModal>
    </Fragment>
  );
};

export default InvoiceFooterTable;
