import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const AddEditExpenses = (props) => {
  const initialFormData = {
    id: "",
    description: "",
    validationError: "",
  };

  const [formData, setFormData] = useState(props.initialFormData);

  useEffect(() => {
    setFormData(props.initialFormData);
  }, [props.initialFormData]);

  const onClickClear = () => {
    setFormData(initialFormData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (props.type === "Add Expense") {
        const res = await axios.post(`${API_BASE_URL}/api/addExpenseList`, {
          expense_data: formData,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          setFormData(initialFormData);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      } else if (props.type === "Edit Expense") {
        const res = await axios.post(`${API_BASE_URL}/api/updateExpenseList`, {
          id: formData.id,
          description: formData.description,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="float-end">
              <Link onClick={onClickClear}>Clear</Link>
            </div>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Expense Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Expense"
                value={formData.description}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    description: event.target.value,
                  })
                }
                maxLength={10}
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Button className="mt-3" type="submit">
              Submit
            </Button>
          </Col>
          <Col xs={12}>
            <p className="text-danger">{formData.validationError}</p>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default AddEditExpenses;
