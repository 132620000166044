import { Fragment, useEffect, useState } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";

const DetailedProfitAndLossTable = () => {
  const [profitAndLoss, setProfitAndLoss] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/getProfitAndLossDetailedData`
        );
        console.log(response.data);
        setProfitAndLoss(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <table className="table table-bordered table-hover table-striped table-info">
      <thead>
        <tr>
          <th>Particulars</th>
          <th className="text-end">Debit by Ledger Group</th>
          <th className="text-end">Debit by Account Group</th>
          <th>Particulars</th>
          <th className="text-end">Credit by Ledger Group</th>
          <th className="text-end">Credit by Account Group</th>
        </tr>
      </thead>
      <tbody>
        {profitAndLoss.map((row, index) => (
          <Fragment key={`row-${index}`}>
            <tr>
              <td>{row.debit_account_group}</td>
              <td className="text-end"></td>
              <td
                className="text-end"
                style={{
                  fontWeight:
                    !row.debit_account_group && !row.credit_account_group
                      ? "bold"
                      : "normal",
                }}
              >
                {row.DEBIT}
              </td>
              <td>{row.credit_account_group}</td>
              <td className="text-end"></td>
              <td
                className="text-end"
                style={{
                  fontWeight:
                    !row.debit_account_group && !row.credit_account_group
                      ? "bold"
                      : "normal",
                }}
              >
                {row.CREDIT}
              </td>
            </tr>
            {row.childData.map((childRow, childIndex) => (
              <tr key={`childRow-${index}-${childIndex}`}>
                <td>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{childRow.debit_ledger_group}
                </td>
                <td className="text-end">{childRow.DEBIT}</td>
                <td className="text-end"></td>
                <td>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{childRow.credit_ledger_group}
                </td>
                <td className="text-end">{childRow.CREDIT}</td>
                <td className="text-end"></td>
              </tr>
            ))}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default DetailedProfitAndLossTable;
