import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

const AddEditAccountGroup = (props) => {
  const initialFormData = {
    id: "",
    baseGroup: "",
    accountGroup: "",
    validationError: "",
  };

  const baseGroupOptions = [
    { value: "Capital Account", label: "Capital Account" },
    { value: "Current Asset", label: "Current Asset" },
    { value: "Current Liability", label: "Current Liability" },
    { value: "Direct Expense", label: "Direct Expense" },
    { value: "Direct Income", label: "Direct Income" },
    { value: "Fixed Asset", label: "Fixed Asset" },
    { value: "InDirect Expense", label: "InDirect Expense" },
    { value: "InDirect Income", label: "InDirect Income" },
    { value: "LongTerm Liability", label: "LongTerm Liability" },
  ];

  const [formData, setFormData] = useState(props.initialFormData);

  useEffect(() => {
    setFormData(props.initialFormData);
  }, [props.initialFormData]);

  const onClickClear = () => {
    setFormData(initialFormData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (props.type === "Add Account Group") {
        const res = await axios.post(`${API_BASE_URL}/api/addAccountGroup`, {
          account_group_data: formData,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          setFormData(initialFormData);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      } else if (props.type === "Edit Account Group") {
        const res = await axios.post(`${API_BASE_URL}/api/updateAccountGroup`, {
          id: formData.id,
          base_group: formData.baseGroup,
          account_group: formData.accountGroup,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="float-end">
              <Link onClick={onClickClear}>Clear</Link>
            </div>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Base Group</Form.Label>
              <Select
                value={baseGroupOptions.find(
                  (option) => option.value === formData.baseGroup
                )}
                onChange={(option) => {
                  setFormData({
                    ...formData,
                    baseGroup: option ? option.value : "",
                  });
                }}
                options={baseGroupOptions}
                placeholder="-- Select Base Group --"
                isSearchable={true}
                isClearable={true}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Account Group</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Account Group"
                value={formData.accountGroup}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    accountGroup: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Button className="mt-3" type="submit">
              Submit
            </Button>
          </Col>
          <Col xs={12}>
            <p className="text-danger">{formData.validationError}</p>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default AddEditAccountGroup;
