import { useEffect, useState } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";

const SimpleProfitAndLossTable = () => {
  const [profitAndLoss, setProfitAndLoss] = useState([]);

  const getProfitAndLossSimpleData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/getProfitAndLossSimpleData`
      );
      console.log(response.data);
      setProfitAndLoss(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfitAndLossSimpleData();
  }, []);

  return (
    <table className="table table-bordered table-hover table-striped table-info">
      <thead>
        <tr>
          <th>Particulars</th>
          <th className="text-end">Debit</th>
          <th>Particulars</th>
          <th className="text-end">Credit</th>
        </tr>
      </thead>
      <tbody>
        {profitAndLoss.map((row, index) => (
          <tr key={index}>
            <td>{row.debit_account_group}</td>
            <td
              className="text-end"
              style={{
                fontWeight:
                  !row.debit_account_group && !row.credit_account_group
                    ? "bold"
                    : "normal",
              }}
            >
              {row.DEBIT}
            </td>
            <td>{row.credit_account_group}</td>
            <td
              className="text-end"
              style={{
                fontWeight:
                  !row.debit_account_group && !row.credit_account_group
                    ? "bold"
                    : "normal",
              }}
            >
              {row.CREDIT}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SimpleProfitAndLossTable;
