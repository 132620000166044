import { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";
import TablePagination from "../components/TablePagination";
import OuterBodyModal from "../components/modals/OuterBodyModal";
import { Button } from "react-bootstrap";
import AddEditExpenses from "../components/modals/Add/AddEditExpenses";
import DeleteConfirmationModal from "../components/modals/DeleteConfirmationModal";

const Expenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add Expense");
  const [showDelModal, setShowDelModal] = useState(false);

  const initialFormData = {
    id: "",
    description: "",
    validationError: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const getExpensesData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/getExpenseList`, {
        params: {
          exclude: "createdAt,updatedAt",
        },
      });
      setExpenses(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getExpensesData();
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setShowDelModal(false);
  }, []);

  const onClickAdd = useCallback(() => {
    setModalTitle("Add Expense");
    setFormData(initialFormData);
    setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickEdit = useCallback(
    (id) => {
      const getExpenseById = async (id) => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/getExpenseById?id=${id}`
          );
          setFormData({
            ...formData,
            id: id,
            description: response.data.description,
          });
        } catch (error) {
          console.log(error);
        }
      };

      console.log("Edit ID: " + id);
      getExpenseById(id);
      setModalTitle("Edit Expense");
      setShowModal(true);
    },
    [formData]
  );

  const onClickDel = useCallback(
    (id) => {
      setFormData({
        ...formData,
        id: id,
      });
      setShowDelModal(true);
    },
    [formData]
  );

  const onSubmitExpenses = useCallback(() => {
    setShowModal(false);
    getExpensesData();
  }, []);

  const handleSubmitAlert = useCallback(
    async (event) => {
      event.preventDefault();
      const clickedButton = event.nativeEvent.submitter;
      const buttonValue = clickedButton.getAttribute("name");

      if (buttonValue === "Yes") {
        console.log("Yes button clicked and id is: " + formData.id);
        try {
          const res = await axios.post(`${API_BASE_URL}/api/delExpenses`, {
            id: formData.id,
          });
          if (res.data.message) {
            console.log(res.data.message);
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        } catch (err) {
          console.log(err);
        }
        setShowDelModal(false);
        getExpensesData();
      } else if (buttonValue === "No") {
        handleCloseModal();
      }
    },
    [formData, handleCloseModal]
  );

  const memoizedExpenses = useMemo(() => expenses, [expenses]);

  return (
    <>
      {expenses ? (
        <Fragment>
          <Button className="m-3" onClick={onClickAdd}>
            + Add
          </Button>
          <TablePagination
            key={JSON.stringify(memoizedExpenses)}
            tabledata={memoizedExpenses}
            initialRowsPerPage="5"
            showEditOption={true}
            showDeleteOption={true}
            onClickEdit={onClickEdit}
            onClickDel={onClickDel}
          ></TablePagination>
          <OuterBodyModal
            modalTitle={modalTitle}
            showModal={showModal}
            handleClose={handleCloseModal}
          >
            <AddEditExpenses
              initialFormData={formData}
              type={modalTitle}
              onHandleSubmit={onSubmitExpenses}
            ></AddEditExpenses>
          </OuterBodyModal>
          <DeleteConfirmationModal
            showModal={showDelModal}
            handleClose={handleCloseModal}
            handleSubmitAlert={handleSubmitAlert}
          />
        </Fragment>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default Expenses;
