import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

const AddEditCustomer = (props) => {
  const initialFormData = {
    id: "",
    type: "client",
    name: "",
    nick_name: "",
    phone_no: "",
    address: "",
    opening_balance: "",
    debit_credit: { value: "DEBIT", label: "DEBIT" },
    bank_details: "",
    validationError: "",
  };

  const debitCredtOptions = [
    { value: "DEBIT", label: "DEBIT" },
    { value: "CREDIT", label: "CREDIT" },
  ];

  const [formData, setFormData] = useState(props.initialFormData);

  useEffect(() => {
    setFormData(props.initialFormData);
  }, [props.initialFormData]);

  const onClickClear = () => {
    setFormData(initialFormData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (props.type === "Add Customer") {
        if (formData.phone_no.length !== 10) {
          setFormData({
            ...formData,
            validationError: "Phone number should have exactly 10 digits.",
          });
          return;
        }
        const res = await axios.post(`${API_BASE_URL}/api/addCustomer`, {
          customer_data: formData,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          setFormData(initialFormData);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      } else if (props.type === "Edit Customer") {
        if (formData.phone_no.length !== 10) {
          setFormData({
            ...formData,
            validationError: "Phone number should have exactly 10 digits.",
          });
          return;
        }
        const res = await axios.post(`${API_BASE_URL}/api/updateCustomer`, {
          id: formData.id,
          name: formData.name,
          nick_name: formData.nick_name,
          phone_no: formData.phone_no,
          address: formData.address,
          opening_balance: formData.opening_balance,
          debit_credit: formData.debit_credit.value,
          bank_details: formData.bank_details,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="float-end">
              <Link onClick={onClickClear}>Clear</Link>
            </div>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Customer Name"
                value={formData.name}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    name: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Customer Nick Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Customer Nick Name"
                value={formData.nick_name}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    nick_name: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Phone No</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Phone No"
                value={formData.phone_no}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    phone_no: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Address"
                value={formData.address}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    address: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Opening Balance</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Opening Balance"
                value={formData.opening_balance}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    opening_balance: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Dr/Cr</Form.Label>
              <Select
                value={formData.debit_credit}
                onChange={(selectedOption) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    debit_credit: selectedOption,
                  }));
                }}
                options={debitCredtOptions}
                // placeholder="-- Select Debit/Credit --"
                isSearchable={true}
                isClearable={true}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Bank Details</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Bank Details"
                value={formData.bank_details}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    bank_details: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Button className="mt-3" type="submit">
              Submit
            </Button>
          </Col>
          <Col xs={12}>
            <p className="text-danger">{formData.validationError}</p>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default AddEditCustomer;
