import { Fragment, useState, useEffect } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Row, Col, Button, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./InvoiceHeaderTable.module.css";
import SearchCustomerModal from "./modals/SearchCustomerModal";

const InvoiceHeaderTable = ({
  type,
  formData,
  updateFormData,
  updateItems,
}) => {
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [customerInvoicesOptions, setCustomerInvoicesOptions] = useState([]);

  const mopOptions = [
    { value: "cash", label: "cash" },
    { value: "credit", label: "credit" },
  ];

  useEffect(() => {
    const getInvoiceNo = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/getInvoiceSeriesByType?series_name=${type}`
        );
        updateFormData((prevFormData) => ({
          ...prevFormData,
          invoiceNo: response.data.next_number,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    getInvoiceNo();
  }, [type, updateFormData]);

  const onClickSearch = () => {
    setShowSearchModal(true);
  };

  const handleCloseModal = () => {
    setShowSearchModal(false);
  };

  const handleUpdateName = async (name) => {
    updateFormData((prevFormData) => ({
      ...prevFormData,
      name: name,
    }));
    if (type === "sales") {
      await getCustomerInvoices(name);
    }
    handleCloseModal();
  };

  const getCustomerInvoices = async (name) => {
    try {
      const res = await axios.post(
        `${API_BASE_URL}/api/getCustomerInvoicesByName`,
        {
          name: name,
        }
      );
      if (res.data.message) {
        const invoices = res.data.message;
        const customerInvoices = invoices.map((invoice) => ({
          value: invoice.value,
          label: invoice.label,
        }));
        setCustomerInvoicesOptions(customerInvoices);
      } else {
        console.log("error: " + res.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerItems = async (selectedInvoices) => {
    try {
      const res = await axios.post(
        `${API_BASE_URL}/api/getCustomerItemsByImport`,
        {
          name: formData.name,
          customerInvoices: selectedInvoices,
        }
      );
      if (res.data.message) {
        updateItems([]);
        res.data.message.forEach((item) => {
          updateItems((prevItems) => [
            ...prevItems,
            {
              sNo: "",
              productName: item.product_name,
              units: item.units,
              selectedUnit: item.selected_unit,
              kgsPerUnit: item.kgs_per_unit,
              qty: item.qty,
              kgs: item.kgs,
              purchaseRate: item.rate,
              cogs: "",
              rate: "",
              subTotal: "",
            },
          ]);
        });
      } else {
        console.log("error: " + res.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col xs={12} lg={6}>
          {/* <h4>Invoice No:</h4> {formData.invoiceNo} */}
          {/* <Form.Group>
            <Form.Label>Invoice No:</Form.Label> */}
          <h4>Invoice No:</h4>
          <Form.Control
            type="text"
            value={formData.invoiceNo}
            onChange={(event) =>
              updateFormData({
                ...formData,
                invoiceNo: event.target.value,
              })
            }
            required
            readOnly={true}
          />
          {/* </Form.Group> */}
        </Col>
        <Col xs={12} lg={6}>
          {/* <h4>
            Name:{" "}
            <Button className={styles["small-button"]} onClick={onClickSearch}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </Button>
          </h4>{" "}
          {formData.name} */}
          {/* <Form.Group>
            <Form.Label>Name:</Form.Label> */}
          <h4>Name:</h4>
          <Row>
            <Col className="col-10">
              <Form.Control
                type="text"
                value={formData.name}
                onChange={(event) =>
                  updateFormData({
                    ...formData,
                    name: event.target.value,
                  })
                }
                required
                readOnly={true}
              />
            </Col>
            <Col className="col-2">
              <Button
                className={styles["small-button"]}
                onClick={onClickSearch}
              >
                <i className="fa-solid fa-magnifying-glass"></i>
              </Button>
            </Col>
          </Row>
          {/* </Form.Group> */}
        </Col>
        <Col xs={12} lg={6}>
          <h4>Invoice Date:</h4>
          <DatePicker
            selected={formData.invoiceDate}
            onChange={(date) =>
              updateFormData({
                ...formData,
                invoiceDate: date,
              })
            }
            className="form-control"
          />
        </Col>
        <Col xs={12} lg={6}>
          <h4>Mode of Payment:</h4>
          <Select
            value={formData.mop}
            onChange={(option) => {
              updateFormData({
                ...formData,
                mop: option,
              });
            }}
            options={mopOptions}
            placeholder="-- Select MOP --"
            isSearchable={true}
            isClearable={true}
          />
        </Col>
        {type === "sales" && (
          <Col xs={12} lg={6}>
            <h4>Import From</h4>
            <Select
              value={formData.customerInvoices.map((value) =>
                customerInvoicesOptions.find((option) => option.value === value)
              )}
              onChange={(selectedOptions) => {
                const selectedInvoices = selectedOptions.map(
                  (option) => option.value
                );
                updateFormData((prevFormData) => ({
                  ...prevFormData,
                  customerInvoices: selectedInvoices,
                }));
                getCustomerItems(selectedInvoices);
              }}
              options={customerInvoicesOptions}
              placeholder="-- Select Invoices --"
              isSearchable={true}
              isClearable={true}
              isMulti={true}
            />
          </Col>
        )}
      </Row>
      <SearchCustomerModal
        modalTitle="Search"
        type={type}
        showModal={showSearchModal}
        handleClose={handleCloseModal}
        handleUpdateName={handleUpdateName}
      />
    </Fragment>
  );
};

export default InvoiceHeaderTable;
