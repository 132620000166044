import { Fragment, useCallback, useState } from "react";
import { Form, Button } from "react-bootstrap";
import SearchProductModal from "./modals/SearchProductModal";
import SelectUnitModal from "./modals/SelectUnitModal";
import styles from "./PurchaseInvoiceItemsTable.module.css";

const SalesInvoiceItemsTable = ({ items, initialItem, updateItems }) => {
  const [showProductModal, setShowProductModal] = useState(false);
  const [showUnitsModal, setShowUnitsModal] = useState(false);
  const [rowNo, setRowNo] = useState(0);

  const handleCloseProductModal = useCallback(() => {
    setShowProductModal(false);
  }, []);

  const handleCloseUnitsModal = useCallback(() => {
    setShowUnitsModal(false);
  }, []);

  const handleOpenProductModal = useCallback((rowIndex) => {
    setRowNo(rowIndex);
    setShowProductModal(true);
  }, []);

  const handleOpenUnitsModal = useCallback((rowIndex) => {
    setRowNo(rowIndex);
    setShowUnitsModal(true);
  }, []);

  const handleUpdateName = useCallback(
    (name, units) => {
      updateItems((prevItems) => {
        const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[rowNo] };
        updatedItem.productName = name;
        updatedItem.units = units;
        updatedItems[rowNo] = updatedItem;
        return updatedItems;
      });
      handleCloseProductModal();
    },
    [handleCloseProductModal, rowNo, updateItems]
  );

  const handleUpdateUnit = useCallback(
    (unit, kgsPerUnit) => {
      updateItems((prevItems) => {
        const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[rowNo] };
        updatedItem.selectedUnit = unit;
        updatedItem.kgsPerUnit = kgsPerUnit;
        updatedItem.kgs =
          updatedItem.qty === "" ? 0 : updatedItem.qty * kgsPerUnit;
        updatedItem.subTotal =
          updatedItem.rate === "" ? 0 : updatedItem.rate * updatedItem.kgs;
        updatedItems[rowNo] = updatedItem;
        return updatedItems;
      });
      handleCloseUnitsModal();
    },
    [handleCloseUnitsModal, rowNo, updateItems]
  );

  const onChangeQty = useCallback(
    (event, index) => {
      const currQty = event.target.value;
      updateItems((prevItems) => {
        const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[index] };
        updatedItem.qty = currQty;
        updatedItem.kgs = currQty === "" ? 0 : currQty * updatedItem.kgsPerUnit;
        updatedItem.cogsAmt = currQty === "" ? 0 : currQty * updatedItem.cogs;
        updatedItem.subTotal =
          updatedItem.rate === "" ? 0 : updatedItem.rate * updatedItem.kgs;
        updatedItems[index] = updatedItem;
        return updatedItems;
      });
    },
    [updateItems]
  );

  const onChangePurchaseRate = useCallback(
    (event, index) => {
      updateItems((prevItems) => {
        const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[index] };
        updatedItem.purchaseRate = event.target.value;
        updatedItems[index] = updatedItem;
        return updatedItems;
      });
    },
    [updateItems]
  );

  const onChangeCOGS = useCallback(
    (event, index) => {
      updateItems((prevItems) => {
        const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[index] };
        updatedItem.cogs = event.target.value;
        updatedItem.cogsAmt =
          updatedItem.qty === "" || event.target.value === ""
            ? 0
            : updatedItem.qty * event.target.value;
        updatedItems[index] = updatedItem;
        return updatedItems;
      });
    },
    [updateItems]
  );

  const onChangeRate = useCallback(
    (event, index) => {
      const currSubTotal = event.target.value;
      updateItems((prevItems) => {
        const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[index] };
        updatedItem.subTotal = currSubTotal;
        updatedItem.rate =
          currSubTotal === ""
            ? 0
            : Number((currSubTotal / updatedItem.kgs).toFixed(2));
        updatedItems[index] = updatedItem;
        return updatedItems;
      });
    },
    [updateItems]
  );

  const handleAddRow = useCallback(() => {
    updateItems((prevItems) => [...prevItems, { ...initialItem }]);
  }, [initialItem, updateItems]);

  const onClickDel = (index) => {
    if (items.length === 1) {
      return; // Do not delete the only row/item
    }

    updateItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });

    setRowNo((prevRowNo) => {
      if (prevRowNo === index) {
        // If the deleted row was the one being edited, reset the rowNo
        return 0;
      } else if (prevRowNo > index) {
        // If the deleted row was before the current rowNo, decrement the rowNo
        return prevRowNo - 1;
      } else {
        // If the deleted row was after the current rowNo, no need to update rowNo
        return prevRowNo;
      }
    });
  };

  return (
    <Fragment>
      <div className="table-responsive">
        <table className="table table-striped text-center table-bordered table-info mt-2">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Product Name</th>
              {/* <th>Units</th>
              <th>Selected Unit</th> */}
              <th>Kgs Per Unit</th>
              <th>No of Qty's</th>
              <th>Kgs</th>
              <th>Purchase Rate</th>
              <th>COGS per Qty</th>
              <th>COGS Amt</th>
              {/* <th>Sales Rate</th> */}
              <th>Sub Total</th>
              {items.length > 1 && <th>Delete</th>}
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <Form.Control
                    type="text"
                    value={item.productName}
                    onChange={() => handleOpenProductModal(index)}
                    className={styles.input200W}
                  />
                </td>
                {/* <td>{item.units}</td>
                <td>{item.selectedUnit}</td> */}
                <td>
                  <Form.Control
                    type="text"
                    value={item.kgsPerUnit}
                    onChange={() => handleOpenUnitsModal(index)}
                    className={styles.input60W}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={item.qty}
                    onChange={(event) => {
                      onChangeQty(event, index);
                    }}
                    className={styles.input60W}
                    required
                  />
                </td>
                <td>{item.kgs}</td>
                <td>
                  <Form.Control
                    type="number"
                    value={item.purchaseRate}
                    onChange={(event) => {
                      onChangePurchaseRate(event, index);
                    }}
                    className={styles.input100W}
                    required
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={item.cogs}
                    onChange={(event) => {
                      onChangeCOGS(event, index);
                    }}
                    className={styles.input60W}
                    required
                  />
                </td>
                <td>{item.cogsAmt}</td>
                {/* <td>
                  <Form.Control
                    type="number"
                    value={item.rate}
                    onChange={(event) => {
                      onChangeRate(event, index);
                    }}
                    className={styles.input100W}
                    required
                  />
                </td> */}
                {/* <td>{item.rate}</td> */}
                <td>
                  <Form.Control
                    type="number"
                    value={item.subTotal}
                    onChange={(event) => {
                      onChangeRate(event, index);
                    }}
                    className={styles.input100W}
                    required
                  />
                </td>
                {items.length > 1 && (
                  <td>
                    <i
                      className={`fa-sharp fa-solid fa-trash text-danger ${styles.clickable}`}
                      onClick={() => {
                        onClickDel(index);
                      }}
                    ></i>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Button onClick={handleAddRow}>Add Row</Button>
      <SearchProductModal
        modalTitle="Search Products"
        showModal={showProductModal}
        handleClose={handleCloseProductModal}
        handleUpdateName={handleUpdateName}
      ></SearchProductModal>
      <SelectUnitModal
        modalTitle="Units Selection"
        showModal={showUnitsModal}
        handleClose={handleCloseUnitsModal}
        handleUpdateUnit={handleUpdateUnit}
        units={items[rowNo] ? items[rowNo].units : ""}
      ></SelectUnitModal>
    </Fragment>
  );
};

export default SalesInvoiceItemsTable;
