import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import axios from "axios";
import { API_BASE_URL } from "../config";
import React, { useState, useEffect } from "react";
import Sidebar from "./SidebarPro";

const PrivateRoute = () => {
  const { jwt, logout, isAuthenticated } = useAuth();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/home`, {
        withCredentials: true,
        headers: {
          "x-token": jwt,
        },
      })
      .then((res) => {
        if (res.data.error) {
          console.log("error: " + res.data.error);
          navigate("/");
        }
        console.log("success: " + res.data.username);
        console.log("isAuthenticated: " + isAuthenticated());
        setData(res.data);
      })
      .catch((err) => console.log("error: " + err));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isAuthenticated() &&
    data && (
      <>
        <div style={{ display: "flex", height: "100vh" }}>
          <Sidebar
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            data={data}
            onLogout={logout}
          />
          <div style={{ flex: 1, overflowY: "auto" }}>
            <section
              onClick={() => {
                setIsCollapsed(true);
              }}
            >
              <Outlet />
            </section>
          </div>
        </div>
      </>
    )
  );
};

export default PrivateRoute;
