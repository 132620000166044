import { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";
import TablePagination from "../components/TablePagination";
import OuterBodyModal from "../components/modals/OuterBodyModal";
import { Button } from "react-bootstrap";
import AddEditVendor from "../components/modals/Add/AddEditVendor";
import DeleteConfirmationModal from "../components/modals/DeleteConfirmationModal";

const Vendor = () => {
  const [vendors, setVendors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add Vendor");
  const [showDelModal, setShowDelModal] = useState(false);

  const initialFormData = {
    id: "",
    type: "vendor",
    name: "",
    nick_name: "",
    phone_no: "",
    address: "",
    opening_balance: "",
    debit_credit: { value: "CREDIT", label: "CREDIT" },
    bank_details: "",
    validationError: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const getVendorsData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/getCustomers`, {
        params: {
          exclude: "createdAt,updatedAt,type",
          type: "vendor",
        },
      });
      setVendors(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVendorsData();
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setShowDelModal(false);
  }, []);

  const onClickAdd = useCallback(() => {
    setModalTitle("Add Vendor");
    setFormData(initialFormData);
    setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickEdit = useCallback(
    (id) => {
      const getVendorById = async (id) => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/getCustomerById?id=${id}`
          );
          setFormData({
            ...formData,
            id: id,
            name: response.data.name,
            nick_name: response.data.nick_name,
            phone_no: response.data.phone_no,
            address: response.data.address,
            opening_balance: response.data.opening_balance,
            bank_details: response.data.bank_details,
          });
        } catch (error) {
          console.log(error);
        }
      };

      console.log("Edit ID: " + id);
      getVendorById(id);
      setModalTitle("Edit Vendor");
      setShowModal(true);
    },
    [formData]
  );

  const onClickDel = useCallback(
    (id) => {
      setFormData({
        ...formData,
        id: id,
      });
      setShowDelModal(true);
    },
    [formData]
  );

  const onSubmitVendors = useCallback(() => {
    setShowModal(false);
    getVendorsData();
  }, []);

  const handleSubmitAlert = useCallback(
    async (event) => {
      event.preventDefault();
      const clickedButton = event.nativeEvent.submitter;
      const buttonValue = clickedButton.getAttribute("name");

      if (buttonValue === "Yes") {
        console.log("Yes button clicked and id is: " + formData.id);
        try {
          const res = await axios.post(`${API_BASE_URL}/api/delCustomers`, {
            id: formData.id,
          });
          if (res.data.message) {
            console.log(res.data.message);
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        } catch (err) {
          console.log(err);
        }
        setShowDelModal(false);
        getVendorsData();
      } else if (buttonValue === "No") {
        handleCloseModal();
      }
    },
    [formData, handleCloseModal]
  );

  const memoizedVendors = useMemo(() => vendors, [vendors]);

  return (
    <>
      {vendors ? (
        <Fragment>
          <Button className="m-3" onClick={onClickAdd}>
            + Add
          </Button>
          <TablePagination
            key={JSON.stringify(memoizedVendors)}
            tabledata={memoizedVendors}
            initialRowsPerPage="5"
            showEditOption={true}
            showDeleteOption={true}
            onClickEdit={onClickEdit}
            onClickDel={onClickDel}
          ></TablePagination>
          <OuterBodyModal
            modalTitle="Add Vendor"
            showModal={showModal}
            handleClose={handleCloseModal}
          >
            <AddEditVendor
              initialFormData={formData}
              type={modalTitle}
              onHandleSubmit={onSubmitVendors}
            ></AddEditVendor>
          </OuterBodyModal>
          <DeleteConfirmationModal
            showModal={showDelModal}
            handleClose={handleCloseModal}
            handleSubmitAlert={handleSubmitAlert}
          />
        </Fragment>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default Vendor;
