import { useEffect, useState } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";

const SimpleTrialBalanceTable = () => {
  const [trialBalance, setTrialBalance] = useState([]);
  const [debitTotal, setDebitTotal] = useState(0);
  const [creditTotal, setCreditTotal] = useState(0);
  const getTrialBalanceSimpleData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/getTrialBalanceSimpleData`
      );
      setTrialBalance(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTrialBalanceSimpleData();
  }, []);

  useEffect(() => {
    // Calculate the total amounts
    let debitSum = 0;
    let creditSum = 0;

    trialBalance.forEach((row) => {
      if (!isNaN(parseFloat(row.DEBIT))) {
        debitSum += parseFloat(row.DEBIT);
      }
      if (!isNaN(parseFloat(row.CREDIT))) {
        creditSum += parseFloat(row.CREDIT);
      }
    });

    setDebitTotal(debitSum);
    setCreditTotal(creditSum);
  }, [trialBalance]);

  return (
    <table className="table table-bordered table-hover table-striped table-info">
      <thead>
        <tr>
          <th>Particulars</th>
          <th className="text-end">Debit</th>
          <th className="text-end">Credit</th>
        </tr>
      </thead>
      <tbody>
        {trialBalance.map((row, index) => (
          <tr key={index}>
            <td>{row.account_group}</td>
            <td className="text-end">{row.DEBIT}</td>
            <td className="text-end">{-row.CREDIT}</td>
          </tr>
        ))}
        <tr>
          <th>Total</th>
          <th className="text-end">{debitTotal.toFixed(2)}</th>
          <th className="text-end">{-creditTotal.toFixed(2)}</th>
        </tr>
      </tbody>
    </table>
  );
};

export default SimpleTrialBalanceTable;
