import { Fragment, useEffect, useState } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";

const DetailedTrialBalanceTable = () => {
  const [trialBalance, setTrialBalance] = useState([]);
  const [debitTotal, setDebitTotal] = useState(0);
  const [creditTotal, setCreditTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/getTrialBalanceDetailedData`
        );
        setTrialBalance(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Calculate the total amounts
    let debitSum = 0;
    let creditSum = 0;

    trialBalance.forEach((row) => {
      if (!isNaN(parseFloat(row.DEBIT))) {
        debitSum += parseFloat(row.DEBIT);
      }
      if (!isNaN(parseFloat(row.CREDIT))) {
        creditSum += parseFloat(row.CREDIT);
      }
    });

    setDebitTotal(debitSum);
    setCreditTotal(creditSum);
  }, [trialBalance]);

  return (
    <table className="table table-bordered table-hover table-striped table-info">
      <thead>
        <tr>
          <th>Particulars</th>
          <th className="text-end">Debit by Ledger Group</th>
          <th className="text-end">Debit by Account Group</th>
          <th className="text-end">Credit by Ledger Group</th>
          <th className="text-end">Credit by Account Group</th>
        </tr>
      </thead>
      <tbody>
        {trialBalance.map((row) => (
          <Fragment key={row.account_group}>
            <tr>
              <th>{row.account_group}</th>
              <th className="text-end"></th>
              <th className="text-end">{row.DEBIT}</th>
              <th className="text-end"></th>
              <th className="text-end">{-row.CREDIT}</th>
            </tr>
            {row.childData.map((childRow) => (
              <tr key={childRow.ledger_group}>
                <td>{childRow.ledger_group}</td>
                <td className="text-end">{childRow.DEBIT}</td>
                <td className="text-end"></td>
                <td className="text-end">{-childRow.CREDIT}</td>
                <td className="text-end"></td>
              </tr>
            ))}
          </Fragment>
        ))}
        <tr>
          <th>Total</th>
          <th></th>
          <th className="text-end">{debitTotal.toFixed(2)}</th>
          <th></th>
          <th className="text-end">{-creditTotal.toFixed(2)}</th>
        </tr>
      </tbody>
    </table>
  );
};

export default DetailedTrialBalanceTable;
