import { Fragment, useState, useEffect, useRef, useCallback } from "react";
import OuterBodyModal from "./OuterBodyModal";
import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import styles from "./SearchCustomerModal.module.css";
import AddEditVendor from "./Add/AddEditVendor";
import AddEditCustomer from "./Add/AddEditCustomer";

const SearchCustomerModal = ({
  modalTitle,
  type,
  showModal,
  handleClose,
  handleUpdateName,
}) => {
  const initialFormData = {
    id: "",
    type: type === "purchase" ? "vendor" : "client",
    name: "",
    nick_name: "",
    phone_no: "",
    address: "",
    opening_balance: "",
    bank_details: "",
    validationError: "",
  };

  const [searchInput, setSearchInput] = useState("");
  const [searchType, setSearchType] = useState(type);
  const [searchResults, setSearchResults] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [activeRow, setActiveRow] = useState(0);
  const tableRef = useRef(null);
  const inputRef = useRef(null);

  const onHandleSearchInput = async () => {
    try {
      const res = await axios.post(
        `${API_BASE_URL}/api/searchCustomersByInput`,
        {
          searchInput: searchInput,
          type: searchType,
        }
      );
      if (res.data.message) {
        setSearchResults(res.data.message);
        setActiveRow(0); // Reset the active row index when search results change
      } else {
        setSearchResults([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setActiveRow((prevActiveRow) =>
        prevActiveRow - 1 >= 0 ? prevActiveRow - 1 : 0
      );
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setActiveRow((prevActiveRow) =>
        prevActiveRow + 1 < searchResults.length
          ? prevActiveRow + 1
          : prevActiveRow
      );
    } else if (event.key === "Enter") {
      event.preventDefault();
      const selectedName = searchResults[activeRow]?.name || "";
      handleUpdateName(selectedName);
    } else {
      onHandleSearchInput();
    }
  };

  useEffect(() => {
    setSearchResults([]); // Clear search results when modal opens or closes
    setActiveRow(0); // Reset the active row index when modal opens or closes
    setSearchInput(""); // Clear the search input when modal opens or closes
    setSearchType(type);
    if (showModal && inputRef.current) {
      inputRef.current.focus(); // Focus the input field when the modal is opened
    }
  }, [showModal, type]);

  useEffect(() => {
    if (tableRef.current) {
      // Scroll to the active row in the table
      const rowElement = tableRef.current.querySelector(
        `tr[data-index="${activeRow}"]`
      );
      if (rowElement) {
        rowElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [activeRow]);

  const handleCloseModal = useCallback(() => {
    setShowAddModal(false);
  }, []);

  const onClickAdd = () => {
    setShowAddModal(true);
  };

  const handleClickRow = (index) => {
    setActiveRow(index);
    inputRef.current.focus();
  };

  const onClickSwith = useCallback(() => {
    if (searchType === "purchase") {
      setSearchType("sales");
    } else if (searchType === "sales") {
      setSearchType("purchase");
    }
    setSearchInput("");
    setSearchResults([]);
    inputRef.current.focus();
  }, [searchType]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "F12") {
        onClickSwith();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onClickSwith]);

  return (
    <Fragment>
      <OuterBodyModal
        modalTitle={modalTitle}
        showModal={showModal}
        handleClose={handleClose}
      >
        <Row>
          <Col xs={12}>
            <Button onClick={onClickSwith}>
              {searchType === "purchase"
                ? "Switch to Customer"
                : "Switch to Vendor"}
            </Button>
          </Col>
          <Col xs={10} className="mt-2">
            <Form.Control
              type="text"
              placeholder="Search by Name or Phone No"
              value={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
              onKeyUp={handleKeyUp}
              ref={inputRef}
            />
          </Col>
          <Col xs={2}>
            <Button onClick={onClickAdd}>+</Button>
          </Col>
        </Row>
        <div className="table-responsive mt-3">
          <table className="table table-striped text-center" ref={tableRef}>
            <thead className="table-info">
              <tr>
                <th>Name</th>
                <th>Phone No</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((result, index) => (
                <tr
                  key={index}
                  data-index={index}
                  className={`${
                    index === activeRow ? styles["active-row"] : ""
                  } ${styles["all-rows"]}`}
                  onClick={() => handleClickRow(index)}
                >
                  <td>{result.name}</td>
                  <td>{result.phone_no}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </OuterBodyModal>
      <OuterBodyModal
        modalTitle="Add Vendor"
        showModal={showAddModal}
        handleClose={handleCloseModal}
      >
        {type === "purchase" ? (
          <AddEditVendor
            initialFormData={initialFormData}
            type="Add Vendor"
            onHandleSubmit={handleCloseModal}
          ></AddEditVendor>
        ) : (
          <AddEditCustomer
            initialFormData={initialFormData}
            type="Add Customer"
            onHandleSubmit={handleCloseModal}
          ></AddEditCustomer>
        )}
      </OuterBodyModal>
    </Fragment>
  );
};

export default SearchCustomerModal;
