import React, { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { API_BASE_URL } from "../../../config";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const AddEditProduct = (props) => {
  const initialFormData = {
    id: "",
    productName: "",
    units: [], // Array to hold selected units
    validationError: "",
  };
  const unitOptions = [
    { value: "BOX", label: "BOX" },
    { value: "BAG", label: "BAG" },
    { value: "GUNNY BAG", label: "GUNNY BAG" },
    { value: "PCS", label: "PCS" },
    { value: "KGS", label: "KGS" },
  ];

  const [formData, setFormData] = useState(props.initialFormData);

  useEffect(() => {
    setFormData(props.initialFormData);
  }, [props.initialFormData]);

  const onClickClear = () => {
    setFormData(initialFormData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (formData.units.length === 0) {
        // Display error message if no units are selected
        setFormData({
          ...formData,
          validationError: "Please select at least one unit",
        });
        return;
      }
      if (props.type === "Add Product") {
        const res = await axios.post(`${API_BASE_URL}/api/addProducts`, {
          product_name: formData.productName,
          units: formData.units,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          // setFormData(props.initialFormData);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      } else if (props.type === "Edit Product") {
        const res = await axios.post(`${API_BASE_URL}/api/updateProducts`, {
          id: formData.id,
          product_name: formData.productName,
          units: formData.units,
        });
        if (res.data.message) {
          console.log("message:" + res.data.message);
          props.onHandleSubmit();
        } else if (res.data.error) {
          console.log("error:" + res.data.error);
          setFormData({ ...formData, validationError: res.data.error });
        } else {
          setFormData({ ...formData, validationError: "Please Try again" });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="float-end">
              <Link onClick={onClickClear}>Clear</Link>
            </div>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Name"
                value={formData.productName}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    productName: event.target.value,
                  })
                }
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>Unit</Form.Label>
              <Select
                value={formData.units.map((value) =>
                  unitOptions.find((option) => option.value === value)
                )}
                onChange={(options) => {
                  setFormData({
                    ...formData,
                    units: options ? options.map((option) => option.value) : [], // Update selected units
                  });
                }}
                options={unitOptions}
                placeholder="-- Select Units --"
                isSearchable={true}
                isClearable={true}
                isMulti={true}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Button className="mt-3" type="submit">
              Submit
            </Button>
          </Col>
          <Col xs={12}>
            <p className="text-danger">{formData.validationError}</p>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default AddEditProduct;
