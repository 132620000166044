import { Fragment, useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import OuterBodyModal from "./OuterBodyModal";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import Select from "react-select";

const SearchMultipleCustomerModal = ({
  modalTitle,
  showModal,
  handleClose,
  handleUpdateNames,
  kgs,
  kgsPerUnit,
}) => {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [validationError, setValidationError] = useState("");

  const getCustomersData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/getCustomers`, {
        params: {
          include: "name",
        },
      });
      const options = response.data.map((customer) => ({
        value: customer.name,
        label: customer.name,
      }));
      setCustomerOptions(options);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSelectedCustomers([]);
    setValidationError("");
    getCustomersData();
  }, [showModal]);

  const onSubmitHandler = (event) => {
    event.preventDefault();

    // Calculate the sum of selected customers' quantities
    const sumKgs = selectedCustomers.reduce(
      (total, customer) => total + parseInt(customer.kgs),
      0
    );

    if (sumKgs !== parseInt(kgs)) {
      setValidationError(
        "PURCHASE KGs: " + parseInt(kgs) + " BUT ORDER OF KGs: " + sumKgs
      );
    } else {
      const isMultiple = selectedCustomers.every(
        (customer) => customer.kgs % kgsPerUnit === 0
      );

      if (!isMultiple) {
        setValidationError(
          "Kgs should be a multiple of " + kgsPerUnit + " kgs."
        );
      } else {
        setValidationError("");
        handleUpdateNames(selectedCustomers);
      }
    }
  };

  return (
    <Fragment>
      <OuterBodyModal
        modalTitle={modalTitle}
        showModal={showModal}
        handleClose={handleClose}
      >
        <Form onSubmit={onSubmitHandler}>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Customers</Form.Label>
                <Select
                  value={selectedCustomers.map((value) =>
                    customerOptions.find(
                      (option) => option.value === value.name
                    )
                  )}
                  onChange={(selectedOptions) => {
                    const updatedCustomers = selectedOptions.map((option) => ({
                      name: option.value,
                      kgs: 0, // Set the initial quantity to 0 or provide a default value
                    }));
                    setSelectedCustomers(updatedCustomers);
                  }}
                  options={customerOptions}
                  placeholder="-- Select Customers --"
                  isSearchable={true}
                  isClearable={true}
                  isMulti={true}
                />
              </Form.Group>
            </Col>
            {selectedCustomers.map((customer, index) => (
              <Row key={index} className="mt-2">
                <Col xs={6}>
                  <Form.Control
                    type="text"
                    value={customer.name}
                    readOnly={true}
                  />
                </Col>
                <Col xs={6}>
                  <Form.Control
                    type="number"
                    value={customer.kgs}
                    onChange={(event) => {
                      const updatedCustomers = [...selectedCustomers];
                      updatedCustomers[index].kgs = event.target.value;
                      setSelectedCustomers(updatedCustomers);
                    }}
                    required
                  />
                </Col>
              </Row>
            ))}

            <Col xs={12}>
              <Button type="submit" className="mt-2">
                Submit
              </Button>
            </Col>
            <Col xs={12}>
              <p className="text-danger">{validationError}</p>
            </Col>
          </Row>
        </Form>
      </OuterBodyModal>
    </Fragment>
  );
};

export default SearchMultipleCustomerModal;
