import { useEffect, useState } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";

const SalesDayBookDetails = ({ formData }) => {
  const [dayBookDetailData, setDayBookDetailData] = useState([]);

  useEffect(() => {
    const getSaleseDayBookDetailData = async () => {
      try {
        const fromDateStr = formData.fromDate.toISOString().split("T")[0];
        const toDateStr = formData.toDate.toISOString().split("T")[0];
        const response = await axios.get(
          `${API_BASE_URL}/api/getSalesDayBookDetailData?fromDate=${fromDateStr}&toDate=${toDateStr}`
        );
        setDayBookDetailData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getSaleseDayBookDetailData();
  }, [formData]);

  return (
    <table className="table table-bordered table-hover table-striped table-info">
      <thead>
        <tr>
          <th>Invoice No</th>
          <th>Date</th>
          <th>Vendor Name</th>
          <th>MOP</th>
          <th>Total Amount</th>
          <th>Discount</th>
          <th>Other Expenses</th>
          <th>Round Off</th>
          <th>Bill Amount</th>
          <th>Notes</th>
          <th>Created At</th>
          <th>Updated At</th>
          <th>Product Name</th>
          <th>Selected Unit</th>
          <th>Kgs Per Unit</th>
          <th>No of Qty's</th>
          <th>Kgs</th>
          <th>Purchase Rate</th>
          <th>COGS</th>
          <th>COGS Amt</th>
          <th>Sales Rate</th>
          <th>Sub Total</th>
        </tr>
      </thead>
      <tbody>
        {dayBookDetailData.map((row, index) => (
          <tr key={index}>
            <td>{row.invoice_no}</td>
            <td>{row.invoice_date}</td>
            <td>{row.customer_name}</td>
            <td>{row.mop}</td>
            <td>{row.total_amt}</td>
            <td>{row.discount}</td>
            <td>{row.other_expenses}</td>
            <td>{row.round_off}</td>
            <td>{row.bill_amount}</td>
            <td>{row.notes}</td>
            <td>{row.createdAt && new Date(row.createdAt).toLocaleString()}</td>
            <td>{row.updatedAt && new Date(row.updatedAt).toLocaleString()}</td>
            <td>{row.product_name}</td>
            <td>{row.selected_unit}</td>
            <td>{row.kgs_per_unit}</td>
            <td>{row.qty}</td>
            <td>{row.kgs}</td>
            <td>{row.purchase_rate}</td>
            <td>{row.cogs}</td>
            <td>{row.cogs_amt}</td>
            <td>{row.sales_rate}</td>
            <td>{row.sub_total}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SalesDayBookDetails;
