import { Fragment, useState, useMemo, useCallback } from "react";
import InvoiceHeaderTable from "../components/InvoiceHeaderTable";
import PurchaseInvoiceItemsTable from "../components/PurchaseInvoiceItemsTable";
import InvoiceFooterTable from "../components/InvoiceFooterTable";
import { Row, Col, Button, Form } from "react-bootstrap";
import styles from "./Purchase.module.css";
import axios from "axios";
import { API_BASE_URL } from "../config";
import OuterBodyModal from "../components/modals/OuterBodyModal";

const Purchase = () => {
  const initialHeaderFormData = {
    invoiceNo: "",
    invoiceDate: new Date(),
    name: "",
    mop: "",
  };

  const initialExpense = useMemo(
    () => ({
      sNo: "",
      expenseId: null,
      amount: "",
    }),
    []
  );

  const initialItem = useMemo(
    () => ({
      sNo: "",
      productName: "",
      units: "",
      selectedUnit: "",
      kgsPerUnit: "",
      qty: "",
      kgs: "",
      rate: "",
      subTotal: "",
      customers: [],
    }),
    []
  );

  const [formDataHeader, setFormDataHeader] = useState(initialHeaderFormData);
  const [items, setItems] = useState([initialItem]);
  const [formDataFooter, setFormDataFooter] = useState({
    discount: "",
    otherExpenses: "",
    expenseList: [initialExpense],
    notes: "",
  });
  const [validationError, setValidationError] = useState("");
  const [validationWarning, setValidationWarning] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const billAmountTemp =
    Number(
      items.reduce((total, item) => total + Number(item.subTotal), 0).toFixed(2)
    ) -
    Number(
      (formDataFooter.discount !== ""
        ? Number(formDataFooter.discount)
        : 0
      ).toFixed(2)
    ) +
    Number(
      formDataFooter.expenseList
        .reduce((total, expense) => total + Number(expense.amount), 0)
        .toFixed(2)
    );

  const billAmount = Number(billAmountTemp.toFixed());

  const totalAmt = Number(
    items.reduce((total, item) => total + Number(item.subTotal), 0).toFixed(2)
  );

  const roundOff = Number(
    (billAmount - Number(billAmountTemp.toFixed(2))).toFixed(2)
  );

  const handleCloseErrorModal = useCallback(() => {
    setShowErrorModal(false);
  }, []);

  const handleCloseSuccessModal = useCallback(() => {
    setShowSuccessModal(false);
  }, []);

  const handleSubmitAlert = useCallback(
    async (event) => {
      event.preventDefault();
      const clickedButton = event.nativeEvent.submitter;
      const buttonValue = clickedButton.getAttribute("name");

      if (buttonValue === "Yes") {
        setIsSubmitting(true);
        try {
          const res = await axios.post(
            `${API_BASE_URL}/api/addPurchaseInvoice`,
            {
              formDataHeader: formDataHeader,
              items: items,
              formDataFooter: formDataFooter,
              totalAmt: totalAmt,
              billAmount: billAmount,
              roundOff: roundOff,
            }
          );
          if (res.data.message) {
            console.log("success");
            window.location.reload();
          } else if (res.data.error) {
            // console.log("error: " + res.data.error);
            setValidationError("Error: " + res.data.error);
            setShowErrorModal(true);
          }
        } catch (err) {
          console.log(err);
        }
      } else if (buttonValue === "No") {
        handleCloseSuccessModal();
      }
    },
    [
      formDataHeader,
      items,
      formDataFooter,
      totalAmt,
      billAmount,
      roundOff,
      handleCloseSuccessModal,
    ]
  );

  const onClickSubmit = async () => {
    if (
      items.length === 0 ||
      items.some((item) => !isItemValid(item)) ||
      !formDataHeader.invoiceNo ||
      !formDataHeader.invoiceDate ||
      !formDataHeader.name ||
      !formDataHeader.mop ||
      (formDataFooter.expenseList.length > 0 &&
        !formDataFooter.expenseList.some((expense) => isExpenseValid(expense)))
    ) {
      setValidationError("Please fill in all item details.");
      setShowErrorModal(true);
      return;
    }
    if (items.some((item) => !isCustomerValid(item))) {
      setValidationWarning(
        "Products not assigned to customers. Do you want to proceed?"
      );
      setShowErrorModal(false);
      setShowSuccessModal(true);
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await axios.post(`${API_BASE_URL}/api/addPurchaseInvoice`, {
        formDataHeader: formDataHeader,
        items: items,
        formDataFooter: formDataFooter,
        totalAmt: totalAmt,
        billAmount: billAmount,
        roundOff: roundOff,
      });
      if (res.data.message) {
        console.log("success");
        window.location.reload();
      } else if (res.data.error) {
        // console.log("error: " + res.data.error);
        setValidationError("Error: " + res.data.error);
        setShowErrorModal(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isItemValid = (item) => {
    return (
      item.productName &&
      item.units &&
      item.selectedUnit &&
      item.kgsPerUnit &&
      item.qty &&
      item.kgs &&
      item.rate &&
      item.subTotal
    );
  };

  const isExpenseValid = (expense) => {
    return expense.expenseId && expense.amount;
  };

  const isCustomerValid = (item) => {
    return item.customers.length > 0;
  };

  return (
    <Fragment>
      <div className="m-2">
        <h1>Purchase Invoice</h1>
        <InvoiceHeaderTable
          type="purchase"
          formData={formDataHeader}
          updateFormData={setFormDataHeader} // Pass the callback function
        ></InvoiceHeaderTable>
        <PurchaseInvoiceItemsTable
          items={items}
          initialItem={initialItem}
          updateItems={setItems}
        ></PurchaseInvoiceItemsTable>
        <InvoiceFooterTable
          formData={formDataFooter}
          updateFormData={setFormDataFooter}
          initialExpense={initialExpense}
          totalAmt={totalAmt}
        ></InvoiceFooterTable>
        <Row>
          <Col className="mt-3 text-end">
            <span>Total Amount: </span>
            <span>{totalAmt}</span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-end">
            <span>Less: </span>
            <span>
              -
              {formDataFooter.discount
                ? parseFloat(formDataFooter.discount).toFixed(2)
                : "0.00"}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-end">
            <span>Other Expenses: </span>
            <span>
              {formDataFooter.otherExpenses
                ? formDataFooter.otherExpenses
                : "0.00"}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-end">
            <span>Round Off: </span>
            <span>{roundOff}</span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-end">
            <span className={styles.billAmountLabel}>Bill Amount: </span>
            <span className={styles.billAmountValue}>{billAmount}</span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-end">
            <Button onClick={onClickSubmit} disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </Col>
        </Row>
      </div>
      <OuterBodyModal
        modalTitle="Alert"
        showModal={showErrorModal}
        handleClose={handleCloseErrorModal}
      >
        <Row>
          <Col xs={12} className="text-center">
            <h6 className="text-danger">{validationError}</h6>
            <Button onClick={() => handleCloseErrorModal()}>Ok</Button>
          </Col>
        </Row>
      </OuterBodyModal>
      <OuterBodyModal
        modalTitle="Alert"
        showModal={showSuccessModal}
        handleClose={handleCloseSuccessModal}
      >
        <Form onSubmit={handleSubmitAlert}>
          <Row>
            <Col xs={12} className="text-center">
              <h6 className="text-danger">{validationWarning}</h6>
            </Col>
            <Col xs={12}>
              <Button type="submit" name="Yes" className="m-2">
                Yes
              </Button>
              <Button type="submit" name="No" className="bg-danger">
                No
              </Button>
            </Col>
          </Row>
        </Form>
      </OuterBodyModal>
    </Fragment>
  );
};

export default Purchase;
