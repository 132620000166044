import { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";
import TablePagination from "../components/TablePagination";
import OuterBodyModal from "../components/modals/OuterBodyModal";
import { Button } from "react-bootstrap";
import AddEditLedgerGroup from "../components/modals/Add/AddEditLedgerGroup";
import DeleteConfirmationModal from "../components/modals/DeleteConfirmationModal";

const LedgerGroup = () => {
  const [ledgerGroupData, setLedgerGroupData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add Account Group");
  const [showDelModal, setShowDelModal] = useState(false);

  const initialFormData = {
    id: "",
    ledgerGroup: "",
    accountGroup: null,
    validationError: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const getLedgerGroupData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/getLedgerGroupData`,
        {
          params: {
            exclude: "createdAt,updatedAt",
          },
        }
      );
      setLedgerGroupData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLedgerGroupData();
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setShowDelModal(false);
  }, []);

  const onClickAdd = useCallback(() => {
    setModalTitle("Add Ledger Group");
    setFormData(initialFormData);
    setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickEdit = useCallback(
    (id) => {
      const getLedgerGroupById = async (id) => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/getLedgerGroupById?id=${id}`
          );
          setFormData({
            ...formData,
            id: id,
            accountGroup: response.data.account_group,
            ledgerGroup: response.data.ledger_group,
          });
        } catch (error) {
          console.log(error);
        }
      };

      console.log("Edit ID: " + id);
      getLedgerGroupById(id);
      setModalTitle("Edit Ledger Group");
      setShowModal(true);
    },
    [formData]
  );

  const onClickDel = useCallback(
    (id) => {
      setFormData({
        ...formData,
        id: id,
      });
      setShowDelModal(true);
    },
    [formData]
  );

  const onSubmitLedger = useCallback(() => {
    setShowModal(false);
    getLedgerGroupData();
  }, []);

  const handleSubmitAlert = useCallback(
    async (event) => {
      event.preventDefault();
      const clickedButton = event.nativeEvent.submitter;
      const buttonValue = clickedButton.getAttribute("name");

      if (buttonValue === "Yes") {
        console.log("Yes button clicked and id is: " + formData.id);
        try {
          const res = await axios.post(`${API_BASE_URL}/api/delLedgerGroup`, {
            id: formData.id,
          });
          if (res.data.message) {
            console.log(res.data.message);
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        } catch (err) {
          console.log(err);
        }
        setShowDelModal(false);
        getLedgerGroupData();
      } else if (buttonValue === "No") {
        handleCloseModal();
      }
    },
    [formData, handleCloseModal]
  );

  const memoizedLedgerGroupData = useMemo(
    () => ledgerGroupData,
    [ledgerGroupData]
  );

  return (
    <>
      {ledgerGroupData ? (
        <Fragment>
          <Button className="m-3" onClick={onClickAdd}>
            + Add
          </Button>
          <TablePagination
            key={JSON.stringify(memoizedLedgerGroupData)}
            tabledata={memoizedLedgerGroupData}
            initialRowsPerPage="5"
            showEditOption={true}
            showDeleteOption={true}
            onClickEdit={onClickEdit}
            onClickDel={onClickDel}
          ></TablePagination>
          <OuterBodyModal
            modalTitle={modalTitle}
            showModal={showModal}
            handleClose={handleCloseModal}
          >
            <AddEditLedgerGroup
              initialFormData={formData}
              type={modalTitle}
              onHandleSubmit={onSubmitLedger}
            ></AddEditLedgerGroup>
          </OuterBodyModal>
          <DeleteConfirmationModal
            showModal={showDelModal}
            handleClose={handleCloseModal}
            handleSubmitAlert={handleSubmitAlert}
          />
        </Fragment>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default LedgerGroup;
